* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
}

input[type="submit"],
/* select {
  -webkit-appearance: none;
} */
select.form-control {
  -webkit-appearance: menulist;
  appearance: menulist
}



img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: red;
}


.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
}

ul {
  list-style: none;
}

i.fa.fa-users {
  margin-right: 9px;
  color: #6B24D6;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #474646;
  -webkit-font-smoothing: antialiased;
  font-family: "Syne", sans-serif !important;
  background-color: #f0f1f7 !important;
  overflow-x: hidden;
}

.logo-top h1,
.logo h1 {
  color: #ffffff;
  font-size: 57px;
  font-weight: bold;
  font-family: monospace;
}

.logo-top h1,
.logo h1 {
  font-size: 35px;
}
.login-area {
  background: url("../images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #111c43;
}

.chamge-text {
  font-size: 35px !important;
}

.change-section .login-content-area {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28%;
}

.dashboard-area {
  width: 100%;
  padding-top: 10px;
}

.admin-header,
.footer-content {
  min-width: 87%;
  margin-left: 244px;
}
.login-content-area {
  border-radius: 20px;
  padding: 50px 20px;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
}
.logo-top {
  text-align: center;
  margin-bottom: 50px;
}
.login-content-area h2 {
  color: #ffffff;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}
.login-content-area form {
  margin: 22px 0 0 0;
}

.login-content-area label.form-label {
  color: #fff !important;
  font-weight: 200;
  font-size: 14px;
  margin: 0 0 2px 0;
}
.login-content-area p {
  font-size: 17px;
  color: #fff;
  text-align: center;
}

.login-content-area .form-control {
  border: none;
  border-radius: 6px;
  padding: 12px;
  color: #fff !important;
  background: #ffffff12;
  font-weight: 400;
  opacity: 1;
  font-size: 14px;
  height: 50px;
  -webkit-text-fill-color: #fff !important;
}

.table-responsive {
  min-height: 550px;
}

.login-content-area .form-control:focus {
  color: #fff;
  background-color: #ffffff12 !important;
  outline: unset !important;
  box-shadow: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}

.login-content-area button {
  color: #7578e6;
  padding: 10px 45px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 17px;
  border: #fff;
  background: #fff;
  box-shadow: -14px 5px 20px 0px rgb(255 255 255 / 0%);
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: block;
  margin-top: 42px;
}

.login-content-area button:hover {
  color: #7578e6 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
ul.pagination {
  justify-content: end;
  background: #ffffff73;
  width: fit-content;
  margin: 20px 0 0 auto;
  padding: 5px 10px;
  border-radius: 4px;
}
.pagination li {
  color: #fff;
  padding: 5px 10px;
}

.swal-modal {
  border-radius: 12px;
  min-width: 415px;
  width: 415px;
}

button.user-back-btn a i {
  margin-right: 7px;
  font-size: 23px;
}

button.user-back-btn a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

button.user-back-btn {
  margin-left: 70px;
  margin-bottom: 30px;
}

.swal-title {
  color: rgb(0 0 0);
}

.pagination {
  margin-top: 14px;
}

.swal-text {
  color: rgb(0 0 0);
  font-size: 18px;
  margin-bottom: 10px;
}

.swal-footer {
  text-align: center;
}

.pagination .disabled {
  opacity: 0.5;
}

.pagination .active a {
  color: #ffffff !important;
  padding: 5px 15px;
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
  border-radius: 3px;
}
.swal-button {
  background-color: #714bc6;
  border-radius: 6px;
  padding: 9px 40px;
  font-size: 14px;
}
.swal-button:not([disabled]):hover {
  background-color: #7578e6;
}

.swal-button:focus {
  box-shadow: unset;
}

.swal-button--cancel {
  background: #000 !important;
  color: #fff;
}

.pagination li a {
  color: #000;
  text-decoration: none;
}

.content {
  padding-left: 270px;
  padding-right: 0;
}

.profile-image img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.mian_content {
  background: #fff;
}
.sidebar {
  width: 260px;
  padding: 0 10px;
  position: fixed;
  height: 100vh;
  top: 0;
  transition: 1s all;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #111c43;
}
.footer-content {
  padding: 12px;
}
.sidebar_option {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 11px;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
  margin: 11px 0;
  border-radius: 5px;
  background: #ffffff17;
}
.sidebar_option img {
  margin-right: 9px;
  width: 20px;
  filter: brightness(0) invert(1);
}
a.sidebar_option.active img {
  filter: brightness(0) invert(1);
}
.sidebar_option svg {
  margin-right: 9px;
  width: 15px;
}
a.sidebar_option.active svg {
  filter: unset;
}

.user-info-area-section {
  width: 77%;
}

/* .sidebar_option img {
  filter: brightness(0);
} */
.logo {
  text-align: center;
  padding: 22px 0 70px;
}

.sidebar_option:hover {
  color: #fff;
}
.barbtn img {
  filter: brightness(0) invert(1);
  width: 32px;
  margin-left: 12px;
}

.dashboard-top-filter-area {
  justify-content: flex-end;
  align-items: end;
}

.content.content-home {
  padding-left: 0 !important;
}

.barbtn {
  background: none;
  padding-left: 15px;
  transition: 1s all;
  position: relative;
  top: 21px;
  left: 13%;
  z-index: 999;
}

.mian_content .content {
  padding-left: 0;
  transition: 1s all;
}

.mian_content.active .content {
  padding-left: 240px;
  transition: 1s all;
}

.mian_content.active .barbtn {
  padding-left: 255px;
  transition: 1s all;
}

.footer-content p {
  color: #000 !important;
  text-align: center;
  font-weight: 600;
}

.custom_height {
  min-height: 773px;
}

.heading-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.add-subscription-btn {
  background: #edd07d;
  border: none;
  color: #000;
}

.userlist-table table {
  background: white;
  border-radius: 15px;
  overflow: hidden;
}

.userlist-table table th {
  padding: 12px 25px !important;
  max-width: 300px;
}

.userlist-table table td {
  padding: 10px 25px;
  max-width: 400px;
}

.user-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  min-width: 60px;
  object-fit: cover;
}

.active-status {
  color: #ffc41b;
}

.status-box {
  display: flex;
  align-items: center;
}

.status-box i {
  background: #edd07d;
  padding: 4px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.mian_content .sidebar {
  left: -270px;
  transition: 1s all;
}

.mian_content.active .sidebar {
  left: 0;
  transition: 1s all;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.admin-header {
  padding: 8px 10px;
  padding-right: 0;
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
}

.mian_content.active .admin-header {
  margin-left: 240px;
  transition: 1s all;
}

.logout-btn button {
  background: none !important;
  border: none !important;
}

.logout-btn button img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.logout-btn .dropdown-menu.show {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 .125rem #0a0a0a0a;
  border: 1px solid #0000000d;
}
.logout-btn .dropdown-item {
  color: #000;
  font-weight: 400;
}

.logout-btn .dropdown-item i {
  margin-right: 12px;
}

.logout-btn s.dropdown-item:focus,
.dropdown-item:hover {
  color: #000;
  background-color: transparent;
}

.dashboad-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 5px;
  color: #000;
  box-shadow: 0 8px 25px 0 rgb(0 0 0 / 10%) !important;
}

.dashboard-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-left h3 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}

.dashboard-right img {
  width: 60px;
  height: 60px;
}

.dashboad-table table tr {
  border-bottom: 1px solid #ffffff08;
}

.dashboad-table {
  margin-top: 30px;
}

.dashboad-table table thead {
  border-bottom: 1px solid #ffffff08;
  color: #000;
}

.dashboad-table table th,
td {
  padding: 13px !important;
  word-break: break-all;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  /* vertical-align: -webkit-baseline-middle; */
}

.icons-actions i {
  color: #fff;
}

.system-log-area-date-picker input:disabled {
  color: #fff !important;
}
.sidebar_option.active {
  color: #ffffff;
  border-left: 3px solid #ffffff;
}
.user-management {
  margin-top: 0;
}

.search-area input:focus {
  background-color: black;
  box-shadow: none;
  color: white;
  border: 1px solid #a8a8a8;
}



.user-toggle .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
}

.user-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

th {
  white-space: nowrap;
}

.transaction-dashboard {
  margin-top: 15px;
}

/* Rounded sliders */
.user-toggle .slider.round {
  border-radius: 34px;
}

.user-toggle .slider.round:before {
  border-radius: 50%;
}

/* toggle-end */
.dashboard-area-heading h2 {
  color: #222;
  font-size: 22px;
  font-weight: 700;

}
.dashboard-area-heading h2 img {
  width: 30px;
}
.audit-request-box .table-responsive-sm {
  padding: 12px 21px;
  border-radius: 12px;
  overflow: auto;
  color: #fff;
  background: #fff;
  box-shadow: 0 .125rem #0a0a0a0a;
}
.login-content-area .form-control::placeholder {
  color: #fff !important;
}
.audit-request-box tr {
  vertical-align: middle;
  /* border-bottom: 1px solid #e1e1e133; */
  color: #fff;
}

.audit-request-box thead {
  border-bottom: 1px solid #e1e1e133;
}

.add-btn button i {
  margin-right: 10px;
}

.mian_content.active .footer-content {
  margin-left: 240px;
  transition: 1s all;
  padding: 20px;
  background: #d7e7f9;
}

.header-dropdown-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-btn.view-btn button i {
  margin-left: 10px;
}

.export-btn.export-btn-nft {
  width: auto;
  margin: 0 10px;
}

.user-info p span {
  color: #fff;
  font-size: 18px;
}

.colored-text {
  color: #086ad8;
}

.user-content-area table {
  background: none;
  box-shadow: none;
}

.management-area-top {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0;
  /* overflow: auto; */
}

.management-area-top .filter-data .css-b62m3t-container {
  width: 100%;
}

.user-management.filter-data {
  width: 37%;
  padding: 0 5px;
}

.commission {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 .125rem #0a0a0a0a;
}
.commission .rounded-circle {
  width: 130px;
  height: 130px;
  min-width: 130px;
  min-height: 130px;
  border-radius: 50% !important;
  object-fit: cover;
  display: flex;
  margin: 0 auto ;
}
.edit-profile-top {
  width: 130px;
  min-width: 130px;
  height: 130px;
  min-height: 130px;
  position: relative;
}
.commission h2 {
  text-align: center;
  font-size: 25px;
  padding-top: 20px;
}
.change-section .commission {
  padding: 30px;
}
.profileImage-select {
  height: 160px;
  position: absolute;
  width: 130px;
  min-height: 160px;
  min-width: 130px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.editprofile {
  border-radius: 7px !important;
}

.editprofile:focus {
  box-shadow: none !important;
  border: 1px solid #00000029 !important;
}
.profilr-update Button {
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
  border: none;
  font-size: 15px;
  margin-top: 18px;
  padding: 8px 22px;
  display: flex;
  margin: 12px auto 0;
}
.userInfo-back {
  background-color: #6B24D6 !important;
  border-color: #6B24D6 !important;
}

.commission-button {
  background: #7578e6 !important;
  color: white !important;
  border: #7578e6 !important;
  padding: 10px 25px !important;
}

.commission .rounded:focus {
  box-shadow: none;
  border-color: #00000029;
}

.primaryclient:hover {
  color: #5d6262 !important;
}

.user-searchbar.search-data {
  width: 33%;
}

.export-btn {
  width: 30%;
}
.upload-image-text {
  color: #7280e2;
  font-weight: 600;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}
.export-btn button {
  border: 1px solid #fff;
  color: #fff !important;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
}

.export-btn i {
  background: none !important;
  font-size: 12px !important;
  margin-right: 5px !important;
  padding: 0 !important;
}

.user-profile img {
  width: 90px;
  height: 90px;
  min-width: 90px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #fff;
}

.pagination-user-management {
  padding-bottom: 15px;
}

.user-management-right-area.mt-0 {
  background-color: #dbdbdb;
  border-radius: 5px;
  min-height: 460px;
}

.user-management-right-area table {
  background: none;
  box-shadow: none;
}

.dashboard-area-top {
  font-size: 26px;
  font-weight: 600;
}

.user-searchbar form {
  width: 164px;
  position: relative;
}

.swal2-html-container {
  color: #000 !important;
}

.list-data {
  cursor: pointer;

}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000da;
  top: 0;
  z-index: 9999;
  left: 0;
}

h2#swal2-title {
  color: #000;
  font-size: 28px;
  font-weight: 500;
}

.swal2-styled.swal2-confirm {
  border-radius: 0.25em;
  color: #fff;
  font-size: 1em;
  background: #35a9ef !important;
  border: #35a9ef !important;
}

.form-select:focus {
  border-color: #d1b9ab !important;
  outline: 0;
  box-shadow: none !important;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginPageLoader div {
  left: 50% !important;
}

.user-searchbar form .form-control {
  background: none;
  border: 1px solid #fff;
  padding-right: 40px;
}

td.action-area i {
  color: #6B24D6;
  border: 1px solid #6B24D6;
  padding: 6px;
  border-radius: 4px;
  margin: 0 4px;
}

.user-searchbar form .form-control::placeholder {
  color: #ffff;
  font-size: 13px;
}

.user-searchbar form button .btn-check:focus+.btn, .btn:focus {
  outline: unset !important;
  box-shadow: unset !important;
  border: unset !important;
}
.nft-area-top {
  justify-content: end;
  margin-bottom: 15px;
}

.nft-area-top .export-btn button {
  margin-right: 10px;
}

.nft-pitcures img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 5px;
}

tr {
  vertical-align: middle;
}

.sub-admin-content-area {
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border-radius: 10px;
  overflow: hidden;
}

.sub-admin-content-area h5 {
  background: #fd7f1117;
  color: #fff;
  font-size: 16px;
  padding: 8px 14px;
  font-weight: 600;
}

.subadmin-search-area {
  padding: 15px 15px;
}

.subadmin-search-area form {
  width: 100% !important;
}

.subadmin-search-area form i {
  right: 0px;
  bottom: 0px;
  padding: 11px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
}

.create-users-form-area .form-label {
  margin-bottom: 0;
  color: #fff;
  font-size: 15px;
}

.create-users-form-area .form-control {
  background: none;
  border: 1px solid #999;
  color: #fff;
}

.create-users-form-area .form-control::placeholder {
  font-size: 14px;
  color: #999;
}

.sub-admin-content-area p {
  color: #fff;
  background: #d6cfff14;
  padding: 9px 15px;
  font-size: 15px;
}

.user-setting-area th {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.add-image-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.file-input {
  position: absolute;
  top: 0px;
  z-index: 1;
  font-size: 0;
  height: 38px;
  cursor: pointer;
  opacity: 0;
}

.user-list-admin h5 {
  background: #fd7f1117;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 14px;
}

.otp-area {
  justify-content: center;
  padding: 45px 0;
}

.otp-area input {
  border: 1px solid hsla(0, 0%, 100%, 0.5490196078431373) !important;
  border-radius: 5px;
  padding: 12px;
  color: #fff !important;
  background-color: #1f1422 !important;
  width: 45px !important;
  height: 45px !important;
}

button.verify-otp-btn {
  padding: 8px 24px;
  margin-top: 10px;
}

tr.user-setting-area {
  background: #2f2334;
}

.sub-admin-content-area td {
  color: #fff;
}

.sub-admin-content-area table tr {
  border-bottom: 1px solid #9b9b9b14;
}

.subadmin-area {
  margin-bottom: 30px;
}

.system-log-area-date-picker input {
  margin-right: 10px;
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 168px;
}

.user-update-box {
  display: flex;
  justify-content: space-between;
  background: #fd7f1117;
}

.user-update-box h5 {
  background: none;
}

.system-log-flex {
  justify-content: space-between;
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.details-name h6 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.details-name p {
  font-size: 15px;
  color: #b9b9b9;
}

.details-name {
  margin-top: 10px;
}

.user-profile-detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-profile-detail img {
  margin-right: 10px;
}

.detail-area-two {
  display: block;
}

.detail-area-two .user-info {
  display: flex;
  justify-content: space-between;
}

.detail-area-two .user-info p {
  width: 25%;
  font-size: 14px;
  padding: 10px 4px 0 6px;
  text-align: left;
  border-right: 1px solid #484848;
}

.detail-area-two .user-info p:first-child {
  padding-left: 0;
}

.form-check-input[type="checkbox"] {
  cursor: pointer;
}

.detail-area-two .user-info p:last-child {
  border: none;
}

.detail-area-two .user-info p span {
  font-size: 14px;
}

.not-data-found {
  text-align: center;
}

.not-found {
  text-align: center;
  color: #fff;
  font-size: 26px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction-area {
  margin-top: 0;
  background-color: rgba(42, 32, 45, 0.5411764705882353);
  box-shadow: inset 0 5px 15px hsl(0deg 0% 64% / 12%);
  border-radius: 10px;
  color: #fff;
  min-height: 610px;
}

.transaction-area table {
  background-color: unset !important;
  box-shadow: none;
  border-radius: 10px;
  color: #fff;
  overflow: hidden;
}

.transaction-area .export-btn button {
  display: block;
  margin-left: auto;
}

.transaction-area .export-btn {
  padding: 15px 15px 0 0;
}

.err-msg {
  color: red;
}

.system-log-area-date-picker label {
  color: #fff;
}

.management-export {
  max-width: 150px;
}

/* width */
::-webkit-scrollbar {
  width: 3px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #61616175;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user-info p,
.user-info p span {
  word-break: break-all;
}

.audit-buttons button {
  color: #000;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 400;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #000;
  background: transparent;
  box-shadow: -14px 5px 20px 0px rgb(8 106 216 / 29%);
}

.audit-buttons {
  text-align: right;
}

.description-box {
  white-space: pre-wrap;
  max-width: 400px;
  /* width: 400px; */
}
.dashboard-main-area {
  margin-top: 15px;
  min-height: calc(100vh - 130px);
  margin-left: 280px;
  position: relative;
  margin-right: 15px;
  padding: 10px;
  border-radius: 12px;
}
.dashboard-cards.appointments {
  background-color: #d7f6e6;
}
.no-found {
  width: 580px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.card-content {
  margin-left: 17px;
}
.card-img-view img {
  width: 60px;
  height: auto;
}
.upload-area i {
  font-size: 120px;
  color: #d0d0d0;
  justify-content: center;
  display: flex;
}

.upload-area input {
  width: 120px;
  height: 120px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: -1;
}

.upload-file-modal .modal-footer {
  justify-content: center;
  border-top: none;
}

.upload-file-modal .modal-header {
  border-bottom: none;
}

.upload-file-modal .modal-title {
  color: #000;
  font-weight: 600;
}

.close-btn.btn.btn-secondary {
  background: #676767 !important;
  border: none;
}

.modal-footer button {
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: 400;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #6B24D6;
  background: #6B24D6;
  /* box-shadow: -14px 5px 20px 0px rgb(8 106 216 / 29%); */
  border-radius: 50px;
}

.btn-close {
  opacity: 1;
}

.icons-actions .btn {
  background: #0a6bd7;
  padding: 5px 11px;
  font-size: 13px;
  border: 0;
  color: #fff !important;
  margin: 4px 0 0 0;
  width: 70%;
}

.no-dat-found-box img {
  text-align: center;
  display: block;
  margin: 20px auto;
  width: 292px;
  border-radius: 22px;
  opacity: 0.4;
}

.no-dat-found-box h3 {
  font-size: 33px;
  font-weight: 700;
  color: #000 !important
}

.outer-form {
  box-shadow: 0 8px 25px 0 rgb(0 0 0 / 8%) !important;
  padding: 18px 40px;
  border: 1px solid #dfdfdf;
}

.outer-form label {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.outer-form input {
  font-family: "Montserrat", sans-serif;
  padding: 8px;
  font-size: 16px;
}

.outer-form h5 {
  font-size: 23px;
  margin: 22px 0;
  color: #000;
  font-weight: 600;
  text-transform: unset;
}

.outer-form h6 {
  font-weight: 600;
  color: #000;
  font-size: 17px;
}

.vulnerability-box .inner-box {
  padding: 22px;
  background: #f2efef;
  margin: 1px 0;
}

.file-upload {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.file-upload input {
  opacity: 0;
  width: 82px;
  height: 82px;
}

.file-upload .upload-img {
  position: absolute;
  top: 0;
  background: #fff;
  padding: 8px;
  border-radius: 10px;
  pointer-events: none;
  cursor: pointer;
  width: 82px;
  height: 82px;
}

.img-view {
  width: 82px;
  height: 82px;
  background: #fff;
  margin: 0 0 13px 12px;
  border-radius: 11px;
  position: relative;
}

.img-view img {
  width: 100%;
  object-fit: cover;
}

.add-more img {
  width: 14px;
  margin: 0 12px 0 0;
}

.vulnerability-boxes {
  background: #f2efeff5;
  padding: 13px 21px;
  border: 1px solid #f2efeff5;
  margin: 12px 0 12px 0;
}

input[type="radio"] {
  appearance: none !important;
  border: 1px solid #d3d3d3 !important;
  width: 16px;
  height: 16px;
  content: none !important;
  outline: none !important;
  margin: 0 9px 0 0;
  border-radius: 2px !important;
}
input[type="radio"]:checked {
  appearance: none;
  outline: none;
  padding: 0;
  content: none;
  border: none;
}

.form-check-input:checked[type="radio"] {
  background-image: none;
  position: relative;
}
input[type="radio"]:checked::before {
  position: absolute;
  color: #fff !important;
  content: "\00A0\2713\00A0" !important;
  font-weight: bolder;
  font-size: 12px;
  top: 0px;
  left: 0px;
}
.list-group .call-text {
  font-size: 15px;
}
.form-check-input:focus {
  box-shadow: unset !important;
}
.form-check-input:focus,
.form-select:focus {
  box-shadow: unset;
}
.dashboard-main-area label svg {
  width: 12px;
}
.dashboard-main-area label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
}
.dashboard-main-area .form-select, .dashboard-main-area .form-control.textarea, .dashboard-main-area .form-control {
  font-size: 13px;
  padding: 12px;
  background: none;
  color: #000;
  border-radius: 7px;
  background: #f3f6f8 !important;
  border: none;
}

.swal2-actions .swal2-confirm {
  background: #704bc5 !important;
}
.audit-buttons.inner-table button {
  padding: 6px 10px;
  font-size: 13px !important;
  width: unset;
  margin: 4px;
  width: 70%;
}

.audit-buttons.inner-table {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-cards {
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  display: flex;
  height: 100%;
  color: #fff;
  position: relative;
  z-index: 1;
}
.dashboard-cards::after {
  content: "";
  background-image: url("../images/bashboard-image-bg.png");
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 11px;
  opacity: 0.2;
}
.card-content h3 {
  font-size: 16px;
  color: #222;
  text-transform: none;
  font-weight: 600;
  margin: 0;
}
td.action-area button {
  background: #6B24D6;
  color: #fff;
  padding: 2px 15px;
  font-size: 14px;
  margin: 0 5px;
}

button.reject-btn.btn.btn-unset {
  background: none;
  border: 1px solid #6B24D6;
  color: #6B24D6;
  padding: 2px 20px;
}

.copy-clipboard i {
  color: #6B24D6;
}

.form-check-input:checked {
  background-color: #6B24D6 !important;
  border-color: #6B24D6 !important;
}

.form-switch .form-check-input {
  background-color: #e8cbb8;
  border: 1px solid #00000017;
  width: 35px !important;
  height: 17px;
}

.text-success {
  color: #00a226 !important;
}

.text-danger {
  color: #f52727 !important;
}

td.copy-clipboard a {
  color: #fff !important;

}

i.fa.fa-clipboard {
  color: #6B24D6;
  margin-left: 9px;
}
.card-content h2 {
  font-size: 50px;
  font-weight: 700;
  color: #222;
  margin: 0;
}
.search-area {
  position: relative;
}

.search-area .fa {
  position: absolute;
  top: 35px;
  right: 20px;
  color: #000000;
}

.fa-close {
  color: #000;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6B24D6;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6B24D6;
}

.main-heading {
  font-size: 32px;
  margin: 31px 0 0 0;
  color: #7578e6;
  font-weight: 700;
}

/* .filter-select {
  margin-top: 36px;
} */

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.custom-shimmer {
  background: linear-gradient(to right, #ddd8d8 8%, #fdfdfd 18%, #dddddd3b 33%);
  background-size: 1000px 100%;
  animation: shimmer 3.2s linear infinite forwards;
}

.shimmer-thumbnail {
  min-width: 80px;
  width: 82%;
  height: 82px !important;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 12px;
  margin: 0 0 0 12px;
}

.img-view img {
  width: 100%;
  height: 82px;
  object-fit: contain;
  padding: 10px;
}

.img-view .fa-close {
  position: absolute;
  right: -4px;
  top: -4px;
  background: #ff0707;
  padding: 2px;
  border-radius: 22px;
  color: #fff !important;
  font-size: 11px;
  display: flex;
  width: 17px;
  height: 17px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard-cards a {
  text-decoration: none;
}

.contact-search {
  top: 47px !important;
}

.view-btn {
  background: #086ad7;
  padding: 6px 10px;
  border-radius: 5px;
  color: #fff;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

p.view-all-btn {
  text-align: end;
  color: #6B24D6;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.note_area {
  height: 182px;
  overflow: auto;
  font-size: 14px !important;
}

.table td a {

  color: #7578e6;
  text-decoration: underline;

}

.table td a:hover {
  color: #fff;
}

.table .wholesaler a:hover {
  color: #000 !important;
}

/* .table td a:hover {
  color: #086ad7 !important;
} */

.img-view.top-logo {
  background: #f2f0f0;
  margin: 0;
}

.btn-view a {
  text-decoration: none !important;
  outline: none;
}

.no-found h2 {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  margin: 12px 0;
  color: #000;
}

.no-found p {
  color: #fff;
}

.login-content-area .spinner-border {
  color: #fff !important;
}

.user-info-area {
  padding: 12px 21px;
  border-radius: 20px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  margin-left: 55px;
  box-shadow: 10px 22px 42px 20px rgb(0 0 0 / 5%);
  color: #000;
  background: #fff !important;
  border: 1px solid #7578e6;
}

.user-info-area-section h6 {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  padding: 13px 0;
}

.user-info-area-section p {
  color: #6B24D6ff;
  font-size: 17px;
  font-weight: 300;
  padding: 10px 0;
  font-weight: 400;
}

.wallet-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back-btn {
  margin-left: 55px;
  margin-top: 3px;
  background-color: #6B24D6;
  border-color: #6B24D6
}

.arrow-down-area {
  position: relative;
}

.arrow-down-area i {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #000;
}

.edit_profile .form-control {
  color: #000 !important;
}

.fa-edit {
  color: #6B24D6;
}

.order-detail-content-area {
  background: none !important;
  border: none !important;
  box-shadow: none;
  overflow: unset;
}

.order-detail-left-area .table>:not(caption)>*>* {
  background-color: unset;

}

.order-detail-left-area p {
  color: #000;

}

.order-detail-left-area {
  box-shadow: 10px 22px 42px 20px rgb(0 0 0 / 5%);
  color: #fff;
  background: #fff;
  border: 1px solid #7578e6;
  border-radius: 20px;
  overflow: hidden;
}

.order-detail-left-area h4 {
  border-bottom: 1px solid #00000026;
  padding: 15px;
  color: #000;
  margin-bottom: 0;
}

.location-address-area .table thead th {
  padding: 8px 0;
}

.location-address-area .table thead {
  background: #fdece2;
}

img.order-detail-product-image {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  border: 1px solid #0000002b;
  background: #0000002b;
  border-radius: 10px;
}

td.product-image-box {
  width: 110px;
}

td.order-product-detail-area p span {
  color: #7578e6;
  font-weight: 800;
}

td.order-product-detail-area p {
  font-size: 13px;
  padding-left: 0;
  margin-bottom: 0;
}

button.tab-left-arrow-btn,
button.tab-right-arrow-btn {
  align-items: center;
  border-radius: 50%;
  bottom: 16px;
  box-shadow: 0 4px 33px 10px rgba(0, 0, 0, .19);
  display: flex;
  height: 30px;
  justify-content: center;
  position: absolute;
  width: 30px;
  z-index: 1;
}

.product-image-left button.tab-left-arrow-btn,
.product-image-left button.tab-right-arrow-btn {
  top: 50%;
}

button.tab-right-arrow-btn {
  background: #7578e6;
  right: 8px;
}

button.tab-left-arrow-btn {
  background: #fbdfce;
  left: 8px;
}

.product-image-left {
  position: relative;
}

:root,
.css-d7l1ni-option:active {
  --swiper-theme-color: #7578e6 !important;
}

.errors {
  color: red;
}

.location-address-area p {
  font-size: 13px;
  padding: 0 12px;
}

td.box-width {
  width: 100px;
}

td.order-product-detail-area {
  padding-left: 0 !important;
  vertical-align: middle;
}

td.order-product-detail-area h6 {
  font-weight: 700;
  text-transform: capitalize;
}

.product-description-area {
  color: #000;
}

.product-description-area h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.product-description-area p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.40);
  margin-bottom: 22px;

}

td.eye-toggle i {
  margin-left: 7px;
}

td.eye-toggle {
  display: flex;
  align-items: center;
}

.load-management-menu.collapse.show,
.load-management-menu.collapse,
.load-management-menu.collapsing {
  padding-left: 35px;
}

.load-management-menu.collapse.show a {
  padding-bottom: 5px !important;
}

.search-area input:focus {
  background-color: #fff;
  color: #000;

}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: -1px !important;
}

.edit-pencil {
  background: #6b24d6;
  color: #fff;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.width-fit {
  width: 200px !important;
}

.form-control.inner-input {
  padding-right: 41px;
}

.truck-detail-box .modal-body ul {
  padding-left: 0;
}

.truck-detail-box .modal-body ul li {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.truck-detail-box .modal-header {
  border-bottom: none;
  padding-bottom: 9px;
}

.truck-detail-box .modal-footer {
  border-top: unset;
  padding-top: 0;
}

.truck-detail-box .modal-body {
  padding-bottom: 0;
}

.truck-detail-box .modal-body ul li span {
  color: gray;
}

table.UserListTable.table td ul li span {
  font-size: 15px;
  font-weight: 900;
}

.UserListTable tbody tr td.loads-view,
.UserListTable tbody tr td span.loads-view {
  color: #7578e6 !important;
  text-decoration: underline;
  cursor: pointer;
}

.heading-top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-top-area-right {
  display: flex;
  align-items: end;
}

.user-type-top {
  margin: 0 10px;
}

.export-csv-btn button:hover {
  background: #7578e6 !important;
  color: #fff !important;
}

.profile-top-action-btn.form-switch {
  display: flex;
  justify-content: end;
}

.profile-top-action-btn.form-switch .form-check-input {
  background-color: #e8cbb8;
  border: 1px solid #00000017;
  width: 55px !important;
  height: 27px;
}

p.user_detail.id-preview-icon {
  margin-left: 16px;
}

p.user_detail.id-preview-icon i {
  color: #6B24D6 !important;
  text-align: center;
  padding-left: 20px;
  font-size: 20px;
  cursor: pointer;
}

.options_data {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 22px;
}

tbody tr:first-child td:first-child {
  width: unset !important;
}

.email-section {
  width: 21%;
}
.audit-request-box h4 {
  font-size: 18px;
  color: #000;
}
.no-data-mid-area {
  padding: 32px;
  border-radius: 12px;
  background: #fff;
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 .125rem #0a0a0a0a;
}
.list-group {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-between;
}
.availabilit-section .list-group .agent-list-name {
  border-radius: 7px;
  background: #f0f1f7;
  border: none;
  font-size: 15px;
  color: #222;
  padding: 10px 12px;
}
button.btn.btn-success {
  font-size: 13px;
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
  border: none;
  border-radius: 6px;
  padding: 0;
}
.action-icons {
  width: 23px;
}
.agent-list-name.list-group-item.active {
  background: #714bc626;
  color: #000;
}
.create-agent-modal .modal-dialog {
  max-width: 750px;
}
.quickstart-card {
  padding: 15px 15px 0px 15px;
  border-radius: 6px;
  margin-top: 9px;
  background: #f3f6f8;
  cursor: pointer;
  height: 100%;
}
.quickstart-card.blank-template-btn p, .quickstart-card p {
  color: dimgray;
  font-size: 14px;
  margin: 0;
}
.quickstart-card:hover {
  background: #f3f6f8;
}
.quickstart-card h6 {
  margin: 0 0 4px 0;
  color: #000;
  font-size: 15px;
  font-weight: 500;
}
.quickstart-card.active {
  background: #8569d038;
}
.template-description h5 {
  text-align: center;
  display: inline-block;
  border-radius: 6px;
  border-bottom: 0;
  font-size: 16px;
  color: #000;
  margin: 0 0 0 6px;
  font-weight: 700;
}
.template-description {
  border-top: 1px solid #00000024;
  padding-top: 16px;
}
.agent-btn-success.btn-success {
  border: 1px solid #704bc5;
  padding: 13px;
  border-radius: 50px !important;
}

.blank-template-btn .btn.btn-success,
.blank-template-btn .btn.btn-success:hover {
  width: 57%;
  padding: 13px;
  border: 1px solid #704bc5;
  background: transparent;
  color: #704bc5;
  font-size: 16px;
  background: #704bc51c;
  cursor: pointer;
}

.with-height-box {
  max-height: 394px;
  overflow-y: auto;
  padding: 9px;
  height: 100%;
}

.availabilit-section.outer-padding-box {
  padding: 8px 9px;
  border-radius: 17px;
}
.availabilit-section .list-group .agent-list-name .btn.btn-danger.btn-sm {
  background: none !important;
  border: none;
  padding: 0;
}
.availabilit-section .list-group .agent-list-name .btn.btn-danger.btn-sm svg {
  width: 14px;
  color: #dc3545;
}
.table-outer-box .UserListTable thead tr th {
  color: #000 !important;
  border-style: none !important;
}

.table-outer-box {
  margin-top: 19px;
}

.pdf-view h5 {
  font-size: 14px;
  margin-right: 22px;
  margin-bottom: 0;
}
.pdf-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  background: #704bc521;
  border-radius: 6px;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 4px;
}
.pdf-view .btn-check:focus+.btn-primary, .pdf-view .btn-primary:focus {
  color: #fff;
  background-color: #714bc6;
  border-color: #714bc6;
}
.pdf-view button {
  background: transparent;
  border: none;
  font-size: 12px;
  border: 1px solid #704bc5;
  color: #704bc5;
  padding: 2px 7px;
}
.pdf-view .btn:hover {
  background-color: #8569d0 !important;
  border-color: #8569d0 !important;
}

/*------------------------------*/

.user_detail {
  color: #6B24D6;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
}

.user_detailField {
  margin-bottom: 2%;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  font-weight: 600;
}

.categoryBtn {
  background-color: #6B24D6 !important;
  border: 1px solid #6B24D6 !important;
  border-radius: 25px !important;
}

.cat_Edit {
  width: 80px;
  padding: 3px !important;
  font-size: 14px !important;
}

.cat_Delete {
  width: 80px;
  font-size: 14px !important;
  padding: 3px !important;
  margin-left: 5px;
  background: red !important;
}

.subcategoryBtn {
  background-color: #6B24D6 !important;
  border: 1px solid #6B24D6 !important;
}

.sub_Edit {
  width: 80px;
  padding: 2px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

.sub_Delete {
  width: 80px;
  margin-left: 5px;
  background: red !important;
  padding: 2px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}

/* .RecentUserList{
  background-color: pink !important;
}
.RecentUserList thead{
  background-color: pink !important;
}
.RecentUserList thead tr{
  background-color: pink !important;
} */
.view-eye {
  color: black !important;
}

.RecentUserList {
  color: #000 !important;
}

.RecentUserList thead tr th {
  background-color: transparent !important;
  color: black !important;
  border-style: none !important;
}

.RecentUserList tbody tr td {
  background-color: transparent !important;
}

.RecentUserList tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.RecentUserList tbody tr td {
  color: #000 !important;
}


/*-----------------*/
.UserListTable {
  color: white !important;
}

.UserListTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important;
  font-size: 14px;
}
.UserListTable tbody tr td {
  background-color: transparent !important;
}

.UserListTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}
.UserListTable tbody tr td {
  color: #000 !important;
  font-size: 13px;
}
tbody td, tbody th {
  border-left: none !important;
}
/*-------------------*/

.categoryTable {
  color: #000 !important;
}

.categoryTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

.categoryTable tbody tr td {
  background-color: transparent !important;
}

.categoryTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.categoryTable tbody tr td {
  color: #000 !important;
}


/*----------------------*/

.subcategoryTable {
  color: white !important;
}

.subcategoryTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}

.subcategoryTable tbody tr td {
  background-color: transparent !important;
}

.subcategoryTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.subcategoryTable tbody tr td {
  color: #000 !important;
}

/*-------------------------*/
.productListTable {
  color: #000 !important;
}

.productListTable thead tr th {
  background-color: transparent !important;
  color: #000 !important;
  border-style: none !important
}


.user-detail-profile img {
  min-width: 120px;
  min-height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #7578e6;
  height: 120px;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #714bc6!important;
}

.no-files-added {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}
.productListTable tbody tr td {
  background-color: transparent !important;
}

.productListTable tbody tr {
  border-style: none !important;
  border-color: transparent !important;

}

.productListTable tbody tr td {
  color: #000 !important;
}



/*---------------product detail-----------------*/

.product-detail {
  border: 1px solid white;
  border-radius: 5px;
}

.product-image-left img {
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: cover;
  border-radius: 26px;
  border: 1px solid #23232330;
}

.product-detail-right h6 {
  font-size: 20px;
  text-transform: uppercase;
  color: #000 !important;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-detail-right h3 {
  color: #000 !important;
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 20px;
}

.product-detail-right h4 span {
  font-size: 15px;
}

.product-detail-right h4 {
  color: #7578e6;
  font-weight: 800;
  margin-bottom: 20px;
}

.product-detail-right p span {
  font-weight: 700;
  color: #7578e6;
  margin-right: 40px;
}

.product-detail-right p {
  font-size: 18px;
  color: #000 !important;
  margin-bottom: 20px;
}

/* 
.product-detail-right {
  padding: 30px 0 0;
} */



.product-availability-order p span {
  color: #7578e6 !important;
  font-weight: 700 !important;
  margin-right: 0 !important;
}

.product-availability-order p {
  font-size: 18px;
  color: #000 !important;
  margin-right: 30px;
  margin-bottom: 0 !important;
}

.product-availability-order {
  display: flex;
  margin-bottom: 20px;
}


.product-multiple-images {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 48px;
}


.product-images-area img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  object-fit: cover;
  border-radius: 5px;
}

.product-images-area {
  width: 120px;
  height: 120px;
  background: #80808061;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  position: relative;
}

.product-images img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  border-radius: 26px;
}

.product-images {
  margin-right: 10px;
  margin-top: 20px;
}

.product-images img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid #23232330;
}

.product-detail.row {
  box-shadow: 10px 22px 42px 20px rgba(0, 0, 0, 0.05);
  color: #fff;
  background: #fff;
  border: 1px solid #7578e6;
  border-radius: 20px;
  padding: 25px;
}

.product-bottom.row {
  color: #000;
  padding: 0 15px;
}

.product-bottom.row h6 {
  font-weight: 600;
  color: #000;
}

.product-bottom.row p {
  color: #7578e6;
  font-size: 15px;
  font-weight: 600;
}

.order-detail-right-area {
  box-shadow: 10px 22px 42px 20px rgb(0 0 0 / 5%);
  background: #fff;
  border: 1px solid #7578e6;
  border-radius: 20px;
  overflow: hidden;
  color: #000;
  padding: 15px;
}

.retailer-info h4 {
  font-size: 18px;
  border-bottom: 1px solid #0000000f;
  padding-bottom: 8px;
}

img.reatiler-image {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #9999997a;
}

a.detail-user-name p {
  margin-bottom: 0;
}

a.detail-user-name,
.detail-user-name a {
  color: #000;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
}

.order-detail-retailer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #00000014;
  padding: 10px 0;
}

.retailer-info {
  background: #fbdfce96;
  padding: 10px;
  border-radius: 10px;
}

.order-detail-retailer-info h6 {
  margin-bottom: 0;
}

.order-detail-retailer-info span {
  color: #8b8b8b;
  font-size: 15px;
}

.add-btn {
  margin-top: 20px;
}

.export-csv-btn button {
  background: #7578e6;
  color: #fff;
  border-radius: 110px;
  padding: 7px 16px;
  font-weight: 400;
  margin-left: 7px;
}

.keynote {
  padding: 20px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border: 1px solid #00000026;
  border-radius: 18px;
  margin-bottom: 7px;
}

.dashboard-cards.total-leads {
  background-color: #c2dfff;
}
button.btn.btn-success.reschedule-btn {
  background: #12225c;
}

button.btn.btn-success.confirm-btn {
  background: #067b18;
  margin-left: 7px;
}

tbody td,
tbody th {
  min-width: fit-content !important;
  max-width: fit-content !important;
}

.profile-data {
  font-size: 15px !important;
  color: dimgray;
}
.edit-profile.btn.btn-primary {
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 20px;
  font-size: 15px;
  margin: 20px auto 0;
  display: flex;
}
.change-section button {
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%) !important;
  border: none !important;
  display: flex !important;
  margin: 35px auto 0 !important;
  justify-content: center;
  padding: 7px 23px !important;
}
.change-section .commission h2 {
  padding: 0;
  font-size: 25px !important;
  margin-bottom: 30px;
}
.action-btn-box {
  min-width: 113px;
}

.call-key-text ul {
  padding: 5px;
  list-style: initial;
  margin-left: 19px;
}

.lable-profile {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  margin-top: 12px;
  display: inline-block;
}
.change-section .fa {
  position: absolute;
  top: 43px;
  right: 14px;
  cursor: pointer;
  font-size: 13px;
  color: dimgray;
}

.logo img {
  width: 152px;
}

.logo-top img {
  width: 200px;
}
.table-outer-box .table td a {
  color: #7578e6 !important;
  font-size: 14px;
}
.status-task {
  border: 1px solid #ab7510;
  padding: 7px 12px;
  color: #ab7510;
  border-radius: 7px;
  text-transform: uppercase;
}
.delete-all-btns {
  border-radius: 10px;
  font-size: 12px;
  padding: 6px 12px;
}
.delete-all-btns svg {
  width: 12px;
  margin-right: 6px;
}
/* .search-area input:focus{
  background-color:transparent;
} */
.under-phone .nav-tabs .nav-item.show .nav-link, .under-phone .nav-tabs .nav-link.active {
  color: #fff;
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
}
.under-phone .nav-item button {
  color: #000;
  border-radius: 9px 9px 0 0;
  font-size: 15px;
  border: none !important;
  padding: 10px 17px;
}
.call-list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-control:focus {
  border-color: transparent !important;
  box-shadow: unset !important;
}
.no-data-mid-area img {
  margin-bottom: 12px;
}
button.call-back-btn {
  background: #e4e2fc !important;
  font-size: 13px;
  color: #000;
  padding: 3px 5px;
  border-radius: 5px;
  border: none !important;
}
button.view-logs-btn {
  background: #c2dfff !important;
  padding: 3px 5px;
  font-size: 13px;
  color: #000;
  border-radius: 5px;
  border: none !important;
}
button.view-summary-btn {
  background: #d7f6e6 !important;
  padding: 3px 5px;
  color: #000;
  font-size: 13px;
  border-radius: 5px;
  border: none !important;
}
.call-summary .modal-title {
  color: #000;
  font-size: 19px;
  font-weight: 600;
}
.call-summary .modal-header {
  border-bottom: 1px solid #00000014;
}
.call-summary .modal-content {
  border-radius: 12px;
  border: none;
}
.call-summary .modal-body p {
  margin: 0;
  font-size: 15px;
  color: dimgray;
}
.btn-close:focus {
  box-shadow: unset !important;
}
td.call-timing {
  white-space: nowrap;
}
.call-log-canvas .offcanvas-body {
  padding: 0;
}
.call-log-canvas .offcanvas-header {
  background: #111c43;
}
.call-log-canvas .offcanvas-title {
  color: #fff;
}
.call-log-canvas .offcanvas-title img {
  filter: brightness(0) invert(1);
  width: 26px;
  margin-left: 5px;
}
.call-log-canvas .offcanvas-header .btn-close {
  filter: brightness(0) invert(1);
  opacity: unset;
}
.call-log-canvas tr:nth-child(even) {
  background: #3825630d !important;
}
.call-log-canvas .table>:not(caption)>*>* {
  background-color: unset !important;
}
ul.pagination {
  justify-content: end;
}
.availabilit-section {
  box-shadow: 0 .125rem #0a0a0a0a;
  background: #fff;
  padding: 12px;
  border-radius: 12px;
}
td.call-message p {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
td.call-message span {
  color: #714bc6;
  text-decoration: underline;
  font-weight: 700;
  font-size: 11px;
  cursor: pointer;
}
button.create-agent {
  background: linear-gradient(125deg,#8569d0 30%,#7578e6 70%);
  width: 100%;
  color: #fff !important;
  padding: 9px;
  border-radius: 7px !important;
}
button.create-agent.btn,
button.create-agent.swal-button,
button.create-agent.btn:hover,
button.create-agent.swal-button:hover .btn,
button.create-agent.swal-button,
button.create-agent.btn:hover,
button.create-agent.swal-button:hover {
    --color: #7d55d5;
    --color2: rgb(255, 255, 255);
    /* padding: 12px 15px 12px 22px; */
    background-color: transparent;
    border-radius: 12px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    font-weight: 700;
    font-size: 17px;
    color: var(--color);
}

button.create-agent.btn-check:focus+.btn-primary,
button.create-agent.btn-primary:focus {
    color: #6b24d6;
    background-color: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
}

button.create-agent.btn::after,
button.create-agent.btn::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    transform: skew(90deg) translate(-50%, -50%);
    position: absolute;
    inset: 50%;
    left: 25%;
    z-index: -1;
    transition: .5s ease-out;
    background-color: var(--color);
}

button.create-agent.btn::before,
button.create-agent.swal-button::before {
    top: -50%;
    left: -25%;
    transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

button.create-agent.btn:hover::before,
button.create-agent.swal-button.btn:hover::before {
    transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

button.create-agent.btn:hover::after,
button.create-agent.swal-button.btn:hover::after {
    transform: skew(45deg) translate(-50%, -50%);
}

button.create-agent.btn:hover,
button.create-agent.swal-button:hover {
    color: var(--color2);
}
.agent-settings-area h3 {
  font-size: 20px;
  color: #222;
  font-weight: 500;
  margin: 0;
}
.agent-settings-area input, .agent-settings-area textarea {
  background: #f3f6f8 !important;
}
.agent-settings-area textarea {
  height: 130px;
}

.react-tel-input .form-control, .react-tel-input .flag-dropdown {
  border: 1px solid #cacaca47 !important;
}
.agent-settings-area form button {
  padding: 8px 23px !important;
  font-size: 14px !important;
  display: flex;
  margin: 0 0 0 auto;
}
.knowledge-base-right {
  display: flex;
  align-items: center;
}
.knowledge-base-right .delete-all-btns {
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 6px;
}
.knowledge-base-right button.btn.btn-success {
  padding: 4px 10px;
  border-radius: 6px;
  margin-right: 5px;
}
.availabilit-section.knowledge-base-section table.UserListTable.table tr {
  border-bottom: 1px solid #00000014 !important;
}
.availabilit-section.knowledge-base-section h3 {
  font-size: 20px;
  color: #222;
  font-weight: 500;
  margin: 0 0 4px 0;
}
.availabilit-section.knowledge-base-section table.UserListTable.table td span {
  border: 1px solid #000;
  text-transform: uppercase;
  padding: 3px 5px;
  font-size: 12px;
  border-radius: 4px;
}
span.done-btn {
  color: #7578e6 !important;
  border: 1px solid #7578e6 !important;
}
span.processing-btn {
  color: #4caf50 !important;
  border: 1px solid #4caf50 !important;
}
span.pending-btn {
color: red !important;
border: 1px solid red !important;
}
.create-agent-area h5 {
  font-size: 17px;
  color: #000;
  margin-top: 12px;
}

.create-agent-area img {
  width: 50px;
}
.create-agent-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
}
.config-area input {
  background: #f3f6f8 !important;
}
.config-area button.btn-success {
  padding: 6px 15px;
  font-size: 15px;
  display: flex;
  margin: 0 0 0 auto;
}
.phone-number-config-left button {
  padding: 12px 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px !important;
}
.under-phone.phone-number-config-right .nav.nav-tabs {
  border-bottom: 1px solid #00000012;
  margin-bottom: 24px !important;
}
.under-phone.phone-number-config-right .card {
  border: 1px solid #e9e9e9b0;
}
.under-phone.phone-number-config-right .card-header {
  background: #714bc626;
  border-bottom: none;
}
.under-phone.phone-number-config-right button.btn.btn-success {
  padding: 7px 9px;
  font-size: 15px;
  display: flex;
  margin: 27px 0 0 auto;
}
.under-phone.phone-number-config-right .card-header h5 {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
}
.under-phone.phone-number-config-right .card-header h6 {
  margin: 0;
  color: dimgray;
  font-weight: 100;
  font-size: 14px;
}
.under-phone.phone-number-config-right .card-body tr th {
  font-size: 14px;
}
.table>:not(:last-child)>:last-child>* {
  border-bottom-color: #80808029;
}
.import-phone-modal .modal-title {
  font-size: 19px;
  color: #000;
  font-weight: 600;
}
.import-phone-modal td {
  cursor: pointer;
}
.import-phone-modal textarea {
  background: #f3f6f8 !important;
  border: none !important;
}
.import-phone-modal textarea::placeholder {
  font-size: 14px;
}
.import-phone-modal input {
  background: #f3f6f8 !important;
  border: none !important;
}
.import-phone-modal input::placeholder {
  font-size: 14px;
}
.import-phone-modal label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin: 0 !important;
}
.import-phone-modal .react-tel-input .form-control {
  border: none !important;
  height: 42px;
}
.import-phone-modal .flag-dropdown {
  border: none !important;
  border-right: 1px solid #cacaca47 !important;
}
.import-phone-modal button.btn.btn-success {
  padding: 7px 1px;
  font-size: 15px;
  border-radius: 6px;
}
.import-phone-modal .modal-content {
  border-radius: 12px;
  border: none;
}
.import-phone-modal.create-agent-modal .modal-footer {
  border-top: none;
  padding: 0 12px 0 12px;
}
.text-danger {
  font-size: 13px;
  margin-top: 3px;
}
.import-phone-modal.create-agent-modal .modal-footer button {
  padding: 6px 15px;
  font-weight: 400;
  font-size: 15px;
  border-radius: 6px !important;
}
.profile-content-data {
  padding: 30px;
}
.profile-content-data span {
  color: #000 !important;
  font-size: 13px;
  margin: 0;
}
.no-files-added img {
  width: 50px;
  min-width: 50px;
}
.availabilit-section.knowledge-base-section .d-flex.justify-content-between {
  flex-wrap: wrap;
}
@media (max-width: 1600px) {
  .system-log-area-date-picker input {
    width: 120px;
  }
  .availabilit-section {
    padding: 18px;
    border-radius: 22px;
}
.availabilit-section .list-group .agent-list-name .btn.btn-danger.btn-sm {
  width: 27px;
  height: 27px;
}

  .card-content h3 {
    font-size: 16px;
  }

  .card-content h2 {
    margin-top: 0;
  }

  p.detail-user-name {
    margin: 0 0 0 12px;
  }

  a.detail-user-name p {
    margin-bottom: 0;
    margin-left: 12px;
  }

  .admin-header,
  .footer-content {
    margin-left: 0;
  }

  .audit-buttons.inner-table button {
    width: 100%;
    margin-right: 0 !important;
    font-size: 11px !important;
  }

  .description-box {
    min-width: 158px;
  }

  .icons-actions .btn {
    width: 100%;
  }

  .detail-area-two .user-info p span {
    font-size: 11px;
  }

  .detail-area-two .user-info p {
    font-size: 12px;
  }

  .custom_height {
    min-height: 680px;
  }

  .mian_content.active .footer-content {
    margin-left: 245px;
  }

  .detail-area-two .user-info p {
    padding: 10px 0px 0 14px;
  }

  .dashboad-content {
    padding: 20px;
  }

  .mian_content.active .admin-header {
    margin-left: 230px;
  }

  .dashboard-left h3 {
    font-size: 22px;
  }

  .dashboard-right img {
    width: 40px;
    height: 40px;
  }

  td {
    font-size: 12px;
    white-space: nowrap;
  }

  th {
    font-size: 14px;
    white-space: nowrap;
  }

  .filter-data .css-b62m3t-container {
    width: 100px;
  }

  .user-info p,
  .user-info p span {
    font-size: 14px;
  }

  .user-info p span i {
    font-size: 14px;
    margin-left: 5px;
  }

  .user-profile img {
    width: 75px;
    height: 75px;
  }

  .sidebar {
    width: 270px !important;
    padding: 0 15px;
  }

  .sidebar_option {
    font-size: 14px;
  }

  .mian_content.active .content {
    padding-left: 235px;
  }

  .mian_content.active .barbtn {
    padding-left: 230px;
  }
}

@media (max-width: 1500px) {

  .dashboard-main-area .form-select,
  .dashboard-main-area .form-control {
    background-color: #fff;
  }
  .dashboard-cards {
    background-position: top;
}

  .dashboard-area-heading h2 {

    margin-bottom: 22px;
    font-size: 30px;

  }
}

@media (max-width: 1400px) {
  .barbtn {
    left: 19%;
  }

  .chamge-text {
    font-size: 27px !important;
  }

  .admin-header,
  .footer-content {
    min-width: 82%;
  }
}

@media (max-width: 1400px) {
  .agent-list-name.list-group-item.active {
    padding: 7px;
}
}

@media (max-width: 1200px) {
  .mian_content.active .sidebar {
    left: -270px;
  }
  .call-text {
    font-size: 12px;
    white-space: nowrap;
}
.btn-success span {
  font-size: 13px !important;
}

  .mian_content .barbtn {
    padding-left: 240px;
  }

  .mian_content.active .barbtn {
    padding-left: 5px;
  }

  .mian_content.active .admin-header {
    margin-left: 0;
  }

  .mian_content.active .barbtn {
    padding-left: 5px;
  }

  .mian_content.active .content {
    padding-left: 0;
  }

  .mian_content.active .footer-content {
    margin-left: 0;
  }

  .mian_content .sidebar {
    left: 0;
  }
}